<template>
  <BaseCard
    variant="basket"
    class="ui-items-start ui-flex ui-flex-col ui-justify-between !ui-gap-none ui-w-full relative"
    :class="variant === 'large' ? 'ui-p-xs' : 'ui-px-none ui-py-xs'"
    :data-test-id="dataTestId"
  >
    <Stack
      tag="div"
      direction="row"
      gap="xs"
      class="ui-w-full relative ui-px-xs"
    >
      <Stack class="w-[72px] h-[72px]" align="center" justify="center">
        <img
          :src="content.image"
          :alt="`${content.title} basket image`"
          @click="emit('link')"
          data-fs="cartTrayProductImg"
          data-test-id="cartItemImage"
          class="cursor-pointer max-w-[72px] max-h-[100px]"
        />
      </Stack>
      <Stack
        tag="div"
        direction="col"
        gap="none"
        class="ui-text-charcoal-default ui-relative ui-w-full"
      >
        <div class="ui-w-full">
          <Stack tag="div" justify="between" align="start" class="ui-w-full">
            <Text
              tag="h3"
              size="md"
              weight="normal"
              class="ui-mb-3xs cursor-pointer"
              :data-test-id="`${dataTestId}Name`"
              @click="emit('link')"
              data-fs="cartTrayProductName"
            >
              {{ content.title }}
            </Text>
            <button
              v-if="props.showRemove"
              @click="isDeleting = !isDeleting"
              :data-test-id="`${dataTestId}RemoveButton`"
              data-fs="cartTrayRemoveButton"
            >
              <span class="ui-sr-only">Delete Item from cart</span>
              <Icon name="trash" :size="16" />
            </button>
          </Stack>

          <Text
            tag="h4"
            size="caption"
            weight="normal"
            class="ui-mb-2xs"
            :data-test-id="`${dataTestId}Sku`"
            data-fs="cartItemSku"
          >
            {{ content.subtitle }}
          </Text>

          <Stack gap="none" align="baseline" class="ui-mb-2xs">
            <Text
              tag="p"
              size="md"
              weight="bold"
              class="ui-w-fit"
              :data-test-id="`${dataTestId}PriceEach`"
              data-fs="cartTrayPriceEach"
            >
              £{{ content.priceEach.toFixed(2) }}
            </Text>
            <Text
              v-if="content.unit"
              tag="span"
              weight="bold"
              size="caption"
              class="ui-px-3xs ui-w-fit"
            >
              {{ content.unit }}
            </Text>
          </Stack>
          <Stack
            tag="div"
            direction="col"
            gap="none"
            :breakpoints="{
              direction: {
                md:
                  variant === 'large' && priceLeft
                    ? 'col'
                    : variant === 'large' && !priceLeft
                    ? 'row'
                    : 'col',
              },
            }"
            align="baseline"
            justify="between"
            class="ui-w-full"
          >
            <CardQuantityControl
              v-if="
                variant === 'large' && content.isTally !== true && showQuantity
              "
              @update:quantity="(qty) => updateTotal(qty)"
              :quantity="quantity"
              :product-i-d="content.id"
              class="ui-mb-xs"
            />

            <Stack gap="xs">
              <Text
                v-if="variant === 'small' || showQuantity === false"
                :data-test-id="`${dataTestId}Quantity`"
                data-fs="cartTrayQuantity"
                class="mb-xs whitespace-nowrap"
              >
                Quantity: {{ content.quantity }} {{ content.tallyUnit }}
              </Text>
              <Text
                v-if="content.isTally && variant === 'large'"
                class="text-mkm-blue-light cursor-pointer"
                @click="emit('changeTally')"
                data-fs="changeTallyCountBtn"
                data-test-id="changeTallyCountBtn"
              >
                Change
              </Text>
            </Stack>
            <Stack
              gap="none"
              align="end"
              class="whitespace-nowrap"
              :class="
                variant === 'large' && priceLeft
                  ? 'md:mr-auto'
                  : variant === 'large' && !priceLeft
                  ? 'md:ml-auto'
                  : 'md:mr-auto'
              "
            >
              <CardPrice
                v-if="content.total"
                :showVat="content.vat !== undefined"
                :hasVat="content.vat ?? false"
                :unit="content.unit ?? 0"
                :total="content.total"
                :variant="variant"
                :price-left="priceLeft"
                :data-id="`${dataTestId}Price`"
              />
            </Stack>
          </Stack>

          <Stack
            v-if="variant === 'large' && content.alert"
            direction="col"
            gap="xs"
            class="mt-xs md:mt-0"
          >
            <Banner
              v-if="content.alert"
              :variant="
                content.alertVariant === 'information' ? 'information' : 'error'
              "
              class="!w-full"
              data-test-id="product-alert"
            >
              {{ content.alert }}
            </Banner>
            <Stack
              v-if="!content.collection"
              gap="2xs"
              align="center"
              class="ui-text-charcoal-light"
              tag="div"
            >
              <Icon name="branch" :size="16" />
              <Text
                tag="p"
                size="sm"
                weight="normal"
                data-test-id="collect-not-available-message"
              >
                Collect from branch not available
              </Text>
            </Stack>
          </Stack>
        </div>
      </Stack>
    </Stack>
    <Stack v-if="variant === 'small'" direction="col" gap="xs" class="mx-xs">
      <Banner
        v-if="content.alert && variant === 'small'"
        :variant="
          content.alertVariant === 'information' ? 'information' : 'error'
        "
        class="!w-full"
      >
        {{ content.alert }}
      </Banner>
      <Stack
        v-if="!content.collection && variant === 'small'"
        gap="2xs"
        align="center"
        class="ui-text-charcoal-light"
        tag="div"
      >
        <Icon name="branch" :size="16" />
        <Text
          tag="p"
          size="sm"
          weight="normal"
          data-test-id="collect-not-available-message"
        >
          Collect from branch not available
        </Text>
      </Stack>
    </Stack>
    <Stack
      :class="[isDeleting ? 'ui-visible' : 'ui-invisible']"
      class="ui-h-full ui-bg-white ui-w-full ui-absolute ui-top-none ui-left-none"
      justify="center"
      align="center"
      direction="col"
      gap="xs"
      tag="div"
      data-test-id="removeConfirmation"
      data-fs="removeConfirmation"
    >
      <Text align="center"> Are you sure you want to remove this? </Text>

      <Stack v-if="showRemove" tag="div" gap="xs">
        <Button
          @click="isDeleting = !isDeleting"
          size="md"
          variant="neutral"
          data-test-id="no"
          data-fs="no"
        >
          No
        </Button>
        <Button
          size="md"
          variant="destructive"
          @click="removeItem"
          data-test-id="yes"
          data-fs="yes"
        >
          Yes
        </Button>
      </Stack>
    </Stack>
  </BaseCard>
</template>

<script setup lang="ts">
import { ref, toRef, watch } from "vue";
import Button from "../Global/Button/Button.vue";
import Icon from "../Icon/Icon.vue";
import Stack from "../Layout/Stack/Stack.vue";
import Text from "../Typography/Text/Text.vue";
import BaseCard from "./BaseCard.vue";
import CardPrice from "./CardPrice.vue";
import CardQuantityControl from "./CardQuantityControl.vue";
import { BasketCardProps } from "./types";

const props = withDefaults(defineProps<BasketCardProps>(), {
  showQuantity: true,
  showRemove: true,
  alertVariant: "error",
  dataTestId: "Product",
});

const quantity = toRef<number>(props.content.quantity);
const isDeleting = ref<boolean>(false);

const emit = defineEmits(["delete", "updateTotal", "link", "changeTally"]);
// emit quantity and display total
const updateTotal = (qty: number) => emit("updateTotal", qty);

const removeItem = () => {
  emit("delete");
  isDeleting.value = false;
};

watch(
  () => props.content.quantity,
  (newValue) => (quantity.value = newValue),
);
</script>
